import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../components/Core";

import styled from "styled-components";
import Helmet from "react-helmet";
import titleImage from "../assets/image/jpeg/parcel-forwarding.jpg";
import iconQuote from "../assets/image/png/quote-icon.png";
import styles from '../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
  .parcel-list li {
    list-style-type: disc;
    margin-left: 20px;
  }
`;

const BadgePost = ({ children }) => (
    <Badge
        mr={3}
        mb={3}
        bg="#eae9f2"
        color="#696871"
        fontSize="16px"
        px={3}
        py={2}
    >
        {children}
    </Badge>
);


const BlogDetails = () => {
    return (
        <>
            <Helmet>
                <title>HotSnail — Mail Redirection &amp; Parcel Forwarding</title>
                <meta name="title" content="HotSnail — Mail Redirection &amp; Parcel Forwarding" />
                <meta name="description" content="Parcel forwarding from Australia to anywhere in the world" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://hotsnail.com.au/" />
                <meta property="og:title" content="HotSnail — Mail Redirection &amp; Parcel Forwarding" />
                <meta property="og:description" content="Parcel forwarding from Australia to anywhere in the world" />
                <meta property="og:image" content="https://www.hotsnail.com.au/hotsnail/img/HotSnailLogo2.png"></meta>

                <link rel="icon" type="image/png" href="/favicon.ico" />

                <html lang="en" />
            </Helmet>

            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"></div>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="hero">
                                    Parcel Forwarding From Australia
                </Title>
                                <Box className="d-flex justify-content-center">
                                    <Text mr={3}>Feb 5, 2021
                  </Text>
                                    <Text>Karl Lehnert
                  </Text>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="pb-0 pt-4">
                    <Container>
                        <Row>
                            <Col lg="12" className="mb-5">
                                {/* <!-- Blog section --> */}
                                <Post>
                                    <div>
                                        <img src={titleImage} alt="Australia Products" className={styles.titleImage} />
                                    </div>
                                    <div>
                                        <p>Have you found a product in Australia you wish to buy but they will not ship it overseas?</p>
                                        <p>With HotSnail, you can shop in Australia like an Aussie and ship to anywhere in the world.</p>
                                        <p>There are various reasons why Australian retailers may not ship directly overseas including:</p>
                                        <ul className='parcel-list'>
                                            <li>Some shipping providers may not provide the service they require.</li>
                                            <li>Distribution rights defined by the manufacturer.</li>
                                            <li>Complications around service and warranty for international customers.</li>
                                            <li>Local customer focus by the vendor.</li>
                                        </ul>
                                        <p>This may mean that the product you are looking for is only available in Australia or is cheaper to buy in Australia and ship overseas than to acquire it locally.</p>
                                        <p>You may also find cost savings, if you have multiple products to purchase in Australia from different vendors by having them shipped as a single item overseas.</p>
                                        <p>That is where we come in, you can buy any item in Australia like an Australian would and ship to a local Australian address. We will then redirect the item(s) to you using a selection of the carrier services we have available.</p>
                                        <p>Here at HotSnail, we are confident that you will find our services to be prompt, convenient and very competitively priced.</p>
                                        <a href="https://members.hotsnail.com.au/signup">Sign up to HotSnail now</a>
                                    </div>
                                </Post>
                                <Box className="d-flex" mt={4}>
                                    <BadgePost>Australian-Products</BadgePost>
                                    <BadgePost>Parcel Forwarding</BadgePost>
                                    <BadgePost>Mail forwarding</BadgePost>

                                </Box>

                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default BlogDetails;
